@use "../abstracts" as *;
@use "../util" as *;

.experience {
    @include flexCenter(flex, space-around, center, row);

    .col-left {
        @include flexCenter(flex, space-around, center, column);

        @include breakpoint(medium) {
            align-items: flex-start;
            width: 60%;
        }

        .experience-info {
            width: 100%;
        }

        table {
            width: 100%;

            thead {
                th {
                    padding: 0 1em 0.75em;
                    text-align: left;
                    color: var(--titleColor);
                }
            }

            tbody {
                vertical-align: baseline;

                td {
                    padding: 0.75em 1em;
                    color: var(--titleColor);

                    strong a {
                        font-size: 0.875em;
                        color: var(--subtitleColor);
                    }

                    .time {
                        color: var(--detailsColor);
                        margin-block: 0.5em 1em;
                        font-size: 0.8125em;
                    }
                }
            }

            .year {
                padding-left: 0;
            }
        }
    }

    .col-right {
        display: none;

        @include breakpoint(medium) {
            display: block;
            width: 40%;

            .avatar {
                @include widthHeight(100%, 100%);
            }
        }
    }
}
