// 768px , 992px, 1200px
$breakpoints: (
    "medium": 48em,
    "large": 62em,
    "xlarge": 75em,
    "xxlarge": 87.5em
);

@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints, $size)) {
        @content;
    }
}
