@use "../util" as *;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font;
}

body {
    background-color: var(--bgColor);
    overflow-x: hidden;
}

html {
    scroll-behavior: smooth;
}

.no-select {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#wrapper {
    opacity: 0;
    display: none;

    &.show {
        opacity: 1;
        display: block;
    }
}
