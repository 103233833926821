@use "../abstracts" as *;
@use "../util" as *;

.header {
    @include widthHeight(100vw, auto);
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    min-height: 10vh;
    max-width: 1920px;
    background-color: var(--bgColor);
    transition: transform 0.5s ease;

    @include breakpoint(xxlarge) {
        max-width: 100vw;
    }

    .nav-bar {
        @include flexCenter(flex, space-between, center, row);
        @include widthHeight(100%, 100%);
        max-width: 1200px;
        padding: 2em;
        position: absolute;
        top: 0;
        box-shadow: 0 10px 30px -10px var(--bgColor);

        @include breakpoint(medium) {
            padding: 4em;
        }

        .nav-list .mobile-menu {
            @include flexCenter(flex, center, center, column);
            @include widthHeight(100vw, 100vh);
            list-style: none;
            position: absolute;
            background-color: var(--bgColor);
            top: 0;
            left: 150%;
            z-index: -1;
            transition: left 0.3s ease;

            @include breakpoint(large) {
                @include widthHeight(fit-content, auto);
                display: block;
                position: initial;
            }

            &.active {
                left: 0;
            }

            li {
                @include breakpoint(large) {
                    display: inline-block;
                }
            }

            a {
                font-size: 1.5em;
                font-weight: 400;
                color: var(--textColor);
                padding: 1.25em;
                display: block;

                @include breakpoint(large) {
                    font-size: 1em;
                }

                &:hover {
                    color: var(--subtitleColor);
                }

                &::after {
                    content: attr(data-after);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%) scale(0);
                    color: rgba(240, 248, 255, 0.02);
                    font-size: 5em;
                    letter-spacing: 0.5em;
                    z-index: -1;
                    transition: letter-spacing 0.3s ease;

                    @include breakpoint(large) {
                        display: none;
                    }
                }
            }

            li:hover a::after {
                transform: translate(-50%, -50%) scale(1);
                letter-spacing: initial;
            }
        }
    }

    &.active {
        transform: translateY(-140px);
    }
}

.hide-scroll {
    overflow-y: hidden;
}
